import {Box, makeStyles, IconButton, Grid, Typography, Link} from '@material-ui/core';
import {ReactComponent as Twitter} from '../assets/twitter.svg'
import {ReactComponent as Discord} from '../assets/discord.svg'
import {ReactComponent as Opensea} from '../assets/opensea.svg'
import {headerData, footerData} from '../utils';

const Footer = () => {
  const classes = useStyles()
  return (
    <Grid container justifyContent='center' className={classes.root} >
      <Grid item sm={6} xs={12} className={classes.contract}>
        <Box>
          <Link underline='always' variant='h3' color='textPrimary' href={footerData.link}>
            {footerData.description}
          </Link>
        </Box>
      </Grid>
      <Grid item sm={6} xs={12} className={classes.social}>
        <Box>
          <IconButton href={headerData.discord} target='_blank'><Discord className={classes.icon}/></IconButton>
          <IconButton href={headerData.opensea} target='_blank'><Opensea className={classes.icon}/></IconButton>
          <IconButton href={headerData.twitter} target='_blank'><Twitter className={classes.icon}/></IconButton>
        </Box>
        <Typography component='div' align='center'>{footerData.copyright}</Typography>
      </Grid>
    </Grid>
   );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(3),
    // display: 'flex',
    // justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      '&  div': {
        marginBottom: theme.spacing(1)
      }
    }
  },
  icon: {
    height: '35px',
    width: '35px',
    fill:'white'
  },
  social: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  contract: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
 
export default Footer;