import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// import { useMediaQuery } from "@material-ui/core";
import Card from './card';
import { teamData } from '../utils';

export default function Team(params) {
  return (
    <Box mt={4} pt={2}>
      <Typography variant='h3' align='center' gutterBottom>{teamData.title}</Typography>
      <Grid container justifyContent='center' spacing={3}>
        {teamData.members.map((item, index) => {
          return (
            <Grid key={item.name} item>
              <Card
                name={item.name}
                img={item.image}
                designation={item.designation}
                twitter={item.link}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
