// import "./../assets/styles/roadmap.scss";
import { roadmapData } from '../utils';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import monke1 from '../assets/1.png';
import monke2 from '../assets/2.png';
import monke3 from '../assets/3.png';
import monke4 from '../assets/4.png';
import StarIcon from '@material-ui/icons/Star';
import 'react-vertical-timeline-component/style.min.css';

const Icon = ({ img }) => {
  return (
    <img
      src={img}
      style={{
        width:'100%',
        clipPath: 'circle(50% at 50% 50%)',
      }}
      alt='monkey'
    />
  );
};

const Roadmap = () => {
  const _lg = useMediaQuery('(min-width:1169px)');
  return (
    <Box component='div' mt={5} pt={2} mb={2} pb={2} >
      <Typography
        variant='h3'
        align='center'
        style={{ borderRadius:'10px'}}
        gutterBottom
      >
        {roadmapData.title}
      </Typography>
      <VerticalTimeline>
        <VerticalTimelineElement
          className='vertical-timeline-element--work'
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date={_lg?'25%':''}
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<Icon img={monke1} />}
        >
          <h3 className='vertical-timeline-element-title'>
            {roadmapData?.itemOne?.heading}
          </h3>
          <p>{roadmapData?.itemOne?.description}</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className='vertical-timeline-element--work'
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date={_lg?'50%':''}
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<Icon img={monke2} />}
        >
          <h3 className='vertical-timeline-element-title'>
            {roadmapData?.itemTwo?.heading}
          </h3>
          <p>{roadmapData?.itemTwo?.description}</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className='vertical-timeline-element--work'
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date={_lg?'75%':''}
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<Icon img={monke3} />}
        >
          <h3 className='vertical-timeline-element-title'>
            {roadmapData?.itemThree?.heading}
          </h3>
          <p>{roadmapData?.itemThree?.description}</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className='vertical-timeline-element--work'
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date={_lg?'100%':''}
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<Icon img={monke4} />}
        >
          <h3 className='vertical-timeline-element-title'>
            {roadmapData?.itemFour?.heading}
          </h3>
          <p>{roadmapData?.itemFour?.description}</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className='vertical-timeline-element--work'
          contentStyle={{ background: 'rgb(17, 75, 122)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(17, 75, 122)' }}
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
          icon={<StarIcon />}
        >
          <h3 className='vertical-timeline-element-title'>
            {roadmapData?.itemFive?.heading}
          </h3>
          <p>{roadmapData?.itemFive?.description}</p>
        </VerticalTimelineElement>
      </VerticalTimeline>
      <Typography
        component='div'
        paragraph={true}
        variant='h6'
        align='center'
        style={{ color: '#e7e8e9', marginTop:'10px' }}
      >
        {roadmapData.vision.description}
      </Typography>
    </Box>
  );
};

export default Roadmap;
