import { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useTheme, useMediaQuery, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
// import Card from '@material-ui/core/Card';
// import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
// import { makeStyles } from '@material-ui/core/styles';
// import grey from '@material-ui/core/colors/blueGrey'
import { aboutData } from '../utils';
import gif from '../assets/gif.gif';
import Cover from './cover'
import logo from '../assets/logo.png'


function Header({ mint, totalSupply, price }) {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
    <Grid
      container
      spacing={5}
      className={classes.root}
      direction={match ? 'column-reverse' : 'row'}
    >
      <Grid className={classes.item} item lg={6} md={6}>
        <img  src={logo} style={{marginBottom: '20px', width:'100%'}}/>
        <Typography style={{whiteSpace:'pre-wrap'}}>{aboutData.description}</Typography>
        <Box component='div' mt={6} className={classes.text}>
          <Box component='div' my={2}>
            <Typography variant='h6' align='center'>
              Pandas Mint: {totalSupply}/ 8,888
            </Typography>
          </Box>
          <Box component='div' mx={1}>
            <Slider
              style={{ color: 'white' }}
              defaultValue={0}
              max={20}
              value={value}
              onChange={handleChange}
            />
          </Box>
          <Typography
            component='div'
            variant='overline'
            mb={2}
            className={classes.gray}
            align='center'
          >
            Mint {value} Pandas ({(value * price).toFixed(3)} ether)
          </Typography>
          <Box style={{ textAlign: 'center', marginBottom: '10px' }}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                mint(value);
              }}
            >
              Mint Pop Pandas
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid className={classes.item + ' ' + classes.right} item lg={6} md={6}>
        <img src={gif} alt='banner' className={classes.media} />
      </Grid>
    </Grid>
    <Cover />
    </>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(1056)]: {
      marginTop: theme.spacing(4),
    },
  },
  media: {
    maxHeight: '400px',
    // borderRadius: '25px',
    clipPath: 'circle(50% at 50% 50%)',
    [theme.breakpoints.down(500)]: {
      maxHeight: '300px',
    },
    // [theme.breakpoints.up('md')]: {
    //   marginRight: '10px',
    // },
  },
  item: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  right: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  gray: {
    color: theme.palette.grey[400],
  },
  text: {
    backgroundColor: '#0a162e',
    border: '1px solid #efefef',
    borderRadius: '12px',
    padding: '1px 0px 6px 12px',
  },
}));

export default Header;
