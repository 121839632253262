import {createTheme, responsiveFontSizes} from '@material-ui/core/styles';

let theme = createTheme({
  palette: {
    primary:{
      main: '#ADEFD1'
    },
    background: {
      default: '#0d1c39'
    },
    text: {
      primary: '#FFF',
      // secondary: '#ADEFD1',
      // secondary: '#D6ED17',
      secondary: '#FEE715',
      // secondary: '#F2AA4C',
      // secondary: '#E94B3C',
    }
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1:{ fontFamily: 'Carter One, cursive' },
    h2:{ fontFamily: 'Carter One, cursive' },
    h3:{ fontFamily: 'Carter One, cursive' },
    h4:{ fontFamily: 'Carter One, cursive' },
    h5:{ fontFamily: 'Carter One, cursive' },
    h6:{ fontFamily: 'Carter One, cursive' },
  },
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 500,
  //     md: 800,
  //     lg: 1280,
  //     xl: 1920,
  //   },
  // }
})

theme = responsiveFontSizes(theme)

export default theme;
// const useStyles = makeStyles(theme => ({
//   root: {
//     margin: theme.spacing(3),
//     width: 345,
//   },
//   media: {
//     height: 140,
//   },
//   title: {
//     color: theme.palette.primary.main
//   }
// }))