import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastContainer } from "react-toastify";
import theme from './theme'
import Connect from "./components/connect";
import Roadmap from "./components/roadmap";
import Team from "./components/team";
import Footer from "./components/footer";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <CssBaseline />
      <Container maxWidth="lg" style={{minWidth:'330px'}}>
        <Connect />
        <Roadmap />
        <Team />
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default App;
