import edArtwork from "./assets/left.png";
import eddArtwork from "./assets/middle.png";
import eddyArtwork from "./assets/right.png";

export const headerData = {
  discord: "#",
  opensea: "https://opensea.io/collection/pop-panda",
  twitter: "https://twitter.com/Pop_PandasNFT",
};

export const footerData = {
  description: "Smart contract",
  copyright: "© Pop Panda Internet Pty Ltd",
  link: "https://etherscan.io/address/0x90aB29076Ebc25AB2641b6B1F34D89038F5f5e01",
};

export const aboutData = {
  title: "BEENFT",
  description:
    "Pop Pandas are a collection of 8,888 randomly generated Pop Panda NFTs whom escaped an authoritarian regime. The Pop Pandas have a reputation in the wider ETH community for only eating bamboo, singing karaoke and rumbl'n and tumbl'n inside the Etherium Blockchain.\n\nThe Pandas are cute, fun, and more importantly enjoy their new found freedom to express themselves freely!",
};

export const teamData = {
  title: "MEET THE TEAM",
  members: [
    {
      name: "Special Person",
      designation: "Community",
      image: eddyArtwork,
      link: "https://twitter.com/MarchBrazen",
    },
    {
      name: "SenorNFTy",
      designation: "Artist",
      image: eddArtwork,
      link: "https://twitter.com/SenorNFTy",
    },
    {
      name: "Pip",
      designation: "Developer",
      image: edArtwork,
      link: "",
    },
  ],
};

export const roadmapData = {
  title: "TEST ROADMAP",
  itemOne: {
    heading: "25%",
    description:
      "Collab PopPandas by NFT artists - auctions and exclusive Pop Panda airdrops will be made to random Pop Pandas’ parents.",
  },
  itemTwo: {
    heading: "50%",
    description:
      "A donation will be made to WWF's (worldwildlife.org) 'Adopt A Panda program' or Panda charity chosen by the community. Donation will be used to continue to help repopulate, rehabilitate and restore habitats for the Giant Panda.",
  },
  itemThree: {
    heading: "75%",
    description:
      "We will be taking back some of the Pop Pandas on OpenSea, purchasing about 10 ETH worth of them. Hold on there, Pop Pandas!",
  },
  itemFour: {
    heading: "100%",
    description:
      "Project Pop will begin. Mission statement: How might we as a community, find a way to adopt a Panda so that we can safeguard the wellbeing of one of nature's most beautiful creations.",
  },
  itemFive: {
    heading: "",
    description:
      "We know how much you all love to support Pandas. We have 1% of secondary fees going to fund habitat preservations for Pandas for community giveaway apart from this we will be giving away a BAYC ape from initial funds.",
  },
  vision: {
    description: "",
  },
};
