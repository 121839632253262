import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {ReactComponent as Twitter} from '../assets/twitter.svg'

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(0,0,0,0)',
    maxWidth: 280,
  },
  media: {
    height: 270,
    width: 'auto'
  },
  content: {
    backgroundColor: 'rgba(0,0,0,0)',
    color:'#ffffff',
    position: 'relative'
  },
  icon: {
    height: '35px',
    width: '35px',
    fill:'white',
    position: 'absolute',
    right: 5,
    bottom: 5
  },
});

export default function MediaCard({img, name, designation, twitter}) {
  const classes = useStyles();

  return (
    <Card className={classes.root} raised={false}>
      <CardActionArea href={twitter}>
        <CardMedia
          component="img"
          className={classes.media}
          image={img}
          title={name}
        />
        <CardContent className={classes.content}>
          <Typography variant="subtitle1">
            {name}
          </Typography>
          <Typography variant="body2">
            {designation}
          </Typography>
          <Twitter className={classes.icon}/>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
